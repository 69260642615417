import '/assets/styles/pages/home.scss';
import PropTypes from 'prop-types';
import loadable from '@loadable/component'
import { useEffect, useState } from 'react';

// Partials
import LazyComponent from '@wearetla/tla-essentials-tools/partials/lazy-component';
import EmptyPlaceholder from '@wearetla/tla-essentials-tools/partials/empty-placeholder';

// Sections
import HighlightedEvents from '/views/sections/highlighted-events';

// Context
import { useApplication } from '/utilities/application';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

// Services
import siteServices from '/services/site'

// Async Sections
const LazyUpcomingEvents = loadable(() => (import('/views/sections/upcoming-events')));
const LazyCasualEvents = loadable(() => (import('/views/sections/casual-events')));
const LazyArmadaConcerts = loadable(() => (import('/views/sections/armada-concerts')));
const LazyEventCategories = loadable(() => (import('/views/sections/event-categories')));
const LazyPartners = loadable(() => (import('/views/sections/partners')));

// const MobileNav = loadable(() => import('/views/sections/mobile-nav'));

const getHighlightedEvents = () => {
	return siteServices.getShowcase('HOME_TOP_FIVE');
}

const Homepage = ({ pageProps }) => {
	const { isApplication } = useApplication();
	const { setError } = useGlobalState();
	const [highlightedEvents, setHighlightedEvents] = useState(pageProps.highlightedEvents ?? null);

	useEffect(() => {
		if(!highlightedEvents) {
			setHighlightedEvents({
				events: null,
			})
			getHighlightedEvents().then((events) => {
				setHighlightedEvents(events)
			}).catch(() => {
				setError();
			})
		}
	}, [highlightedEvents])

	return (
		<main>
			<HighlightedEvents data={highlightedEvents} />
			{!isApplication ?
				<>
					<LazyComponent placeholder={
						<EmptyPlaceholder
							styles={{ height: '78.4rem' }}
							mobileStyles={{ height: '78.4rem' }} />
					}>
						<LazyUpcomingEvents />
					</LazyComponent>

					<LazyComponent placeholder={
						<EmptyPlaceholder
							styles={{ height: '47.7rem' }}
							mobileStyles={{ height: '47.7rem' }} />
					}>
						<LazyCasualEvents />
					</LazyComponent>

					<LazyComponent placeholder={
						<EmptyPlaceholder
							styles={{ height: '64.5rem' }}
							mobileStyles={{ height: '64.5rem' }} />
					}>
						<LazyArmadaConcerts />
					</LazyComponent>

					<LazyComponent placeholder={
						<EmptyPlaceholder
							styles={{ height: '85rem' }}
							mobileStyles={{ height: '85rem' }} />
					}>
						<LazyEventCategories />
					</LazyComponent>

					<LazyComponent placeholder={
						<EmptyPlaceholder
							styles={{ height: '33.2rem' }}
							mobileStyles={{ height: '33.2rem' }} />
					}>
						<LazyPartners />
					</LazyComponent>
				</>
				:
				<>
					<LazyUpcomingEvents />
					<LazyCasualEvents />
					<LazyArmadaConcerts />
					<LazyEventCategories />
				</>
			}
		</main>
	)
}

Homepage.propTypes = {
	pageProps: PropTypes.object
}

Homepage.getServerProps = () => {
	return new Promise((resolve) => {
		Promise.all([
			getHighlightedEvents(),
		]).then(([
			highlightedEvents,
		]) => {
			resolve({
				pageProps: {
					highlightedEvents
				}
			})
		}).catch(() => {
			resolve({
				pageProps: {}
			})
		})
	});
}

export default Homepage