import '/assets/styles/partials/badge.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const availableVariants = [
	'default',
	'warning',
	'text',
	'bg',
	'red-dark',
	'red-darker'
]

export const Badge = ({ children, className, variant, icon, big, small, outline, ...props }) => {
	return (
		<span
			{...props}
			className={classNames('badge', className, `variant-${variant}`, { big, small, outline, 'icon-only': !children })}>
			{icon &&
				<Icon className="badge-icon" name={icon} />
			}
			{children &&
				<span>{children}</span>
			}
		</span>
	)
}

Badge.defaultProps = {
	variant: 'default'
}

Badge.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	variant: PropTypes.oneOf(availableVariants),
	icon: PropTypes.string,
	big: PropTypes.bool,
	small: PropTypes.bool,
	outline: PropTypes.bool,
}

export default Badge;