export const createEventBtnParams = (event, calendar, option) => {
	return calendar.buy_btn_link?.length ? {
		hollowLink: true,
		// tag: 'link',
		href: calendar.buy_btn_link,
		raw: true,
		target: '_blank',
		// rel: 'noopener noreferrer',
	}
		:
	{
		hollowLink: true,
		href: 'checkout',
		params: {
			id: event.id,
			calendarId: calendar?.id,
			...(option ? { optionId: option.id } : {})
		},
	};
}

export default createEventBtnParams;