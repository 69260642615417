import '/assets/styles/sections/highlighted-events.scss';
import { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Functions
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';
import createEventBtnParams from '/functions/create-event-btn-params';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Placeholder from '/views/partials/placeholder';
import Btn from '/views/partials/btn';
import Badge from '/views/partials/badge';

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

// Static Assets
import image_bg from '/assets/images/highlighted-events/bg.jpg';
import image_bg_2x from '/assets/images/highlighted-events/bg@2x.jpg';

import image_bg_mobile from '/assets/images/highlighted-events/bg-mobile.jpg';
import image_bg_mobile_2x from '/assets/images/highlighted-events/bg-mobile@2x.jpg';
import image_bg_mobile_3x from '/assets/images/highlighted-events/bg-mobile@3x.jpg';

export const HighlightedEvents = ({ data }) => {
	const { desktop, mobile } = useBreakpoints();

	const events = useMemo(() => ( data?.events?.length ? data.events.filter(e => e.calendar?.full_date).slice(0, 5).map((event, nth) => {
		const date = new Date(event.calendar.full_date);

		const highlight = nth === 0;
		const day = formatDate(date, 'd', { parsed: true });
		const month = formatDate(date, 'MMM', { parsed: true });
		const time = formatDate(date, 'HH:mm', { parsed: true });
		
		return ({
			...event,
			highlight,
			day,
			month,
			time,
			image: event.image?.asset_paths?.[highlight ? 'thumbnail' : 'mini'] ?? false,
		});
	}) : [null, null, null, null, null]), [data]);

	return <div className="section highlightedevents">
		<div className="highlightedevents-wrap wrapper">
			{desktop &&
				<h1 className="highlightedevents-heading">GECEIFTEBITER</h1>
			}
			<ul className="highlightedevents-list wrapper inner">
				{events.map((event, nth) => (event ?
					<li
						key={event.id}
						className={classNames('list-item', { highlight: event?.highlight })}>
						<Link
							className="item-link"
							href="eventDetail"
							params={{ id: event.id, slug: event.slug, calendarId: event.calendar?.id }}>
							<Img
								cover
								lazy={!event?.highlight}
								className="item-image"
								src={event.image} />
							<div className="item-top">
								<div className="item-datetime">
									<span className="datetime-day">{event.day}</span>
									<div className="datetime-month">{event.month}</div>
									<div className="datetime-time">{event.time}</div>
								</div>
							</div>
							<div className="item-bottom">
								<strong className="item-title">{event.title}</strong>

								<div className="item-features">
									{event.calendar?.event_location &&
										<Badge
											className="features-feature item-location"
											small={mobile && !event.highlight}
											big={desktop && event.highlight}
											title={event.calendar.event_location}
											icon="map-pin">
											{event.calendar.event_location}
										</Badge>
									}
									{/* {event.type?.icon &&
										<Badge	
											className="features-feature item-type"
											small={mobile && !event.highlight}
											big={desktop && event.highlight} icon={event.type.icon}
											title={event.type.name} />
									} */}
								</div>

								<div className="item-controls">
									{event.calendar &&
										<Btn
											{...createEventBtnParams(event, event.calendar)}
											className={classNames(
												'item-buybtn',
												'primary',
												{
													'small': !event.highlight,
													'text-small': !event.highlight,
													'big': desktop && event.highlight,
													'medium': !desktop && event.highlight,
													'text-big': desktop && event.highlight
												})}>
											BİLET AL
										</Btn>
									}
									<Btn
										className={classNames('item-detailbtn', 'white', 'nude', { small: !event.highlight, big: event.highlight })}>
										Detayları Gör
									</Btn>
								</div>
							</div>
						</Link>
					</li>
					:
					<Placeholder
						key={nth}
						className={classNames('list-item', { highlight: nth === 0 })} />
				))}
			</ul>
		</div>
		<div className="highlightedevents-bgwrap">
			<Img
				cover
				src={desktop ? image_bg : image_bg_mobile}
				srcSet={desktop ? `${image_bg_2x} 2x` : `${image_bg_mobile_2x} 2x, ${image_bg_mobile_3x} 3x`}
				className="bgwrap-image" />
		</div>
	</div>
}

HighlightedEvents.propTypes = {
	data: PropTypes.object,
}

export default HighlightedEvents;